<template>
  <div id="feedback">
    <b-jumbotron class="text-center mb-0" :style="{ background: `require(@/assets/slideOne.jpg) center center / cover no-repeat` }">
      <h1 class="display-1 mt-6">{{$t('feedback.header')}}</h1>
      <h3 class="h3">{{$t('feedback.subHeader', { email: 'cas.boots@avsnl.com' })}}</h3>
    </b-jumbotron>
  </div>
</template>

<script>
export default {
    name: 'feedback'
}
</script>

<style scoped>
.display-1 {
  font-size: 4.5em;
}
@media screen and (max-width: 350px){
   .display-1 {
     font-size : 2.5em;
     word-break: word-break;
   }
}
</style>